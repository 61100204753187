import React from 'react'
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {Messages} from "primereact/messages";
import RegistrierenDialog from "./RegistrierenDialog";
import KennwortVergessenDialog from "./KennwortVergessenDialog";
import {Toast} from "primereact/toast";
import PropTypes from "prop-types";

const initialState = {
    name: "",
    password: "",
    showRegistrieren: false,
    showKennwortVergessen: false
};

class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = initialState;

        this.handleNameChange = this.handleNameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handelKeyDown = this.handelKeyDown.bind(this);
        this.callBackLoginFehlerhaft = this.callBackLoginFehlerhaft.bind(this);
        this.showError = this.showError.bind(this);
    }

    showError(message) {
        this.messages.show({
            sticky: true,
            severity: 'error',
            summary: '',
            detail: message
        });
    }

    handelKeyDown(e) {
        if (e.keyCode === 13) {
            // enter
            this.doLogin();
        }
    }

    handleNameChange(value) {
        this.setState({name: value});
    }

    handlePasswordChange(value) {
        this.setState({password: value});
    }

    doLogin() {
        this.messages.clear();
        if (this.state.name.length === 0 || this.state.password.length === 0) {
            return;
        }
        this.props.login(this.state.name, this.state.password, () => "", this.callBackLoginFehlerhaft);
    }

    callBackLoginFehlerhaft(message) {
        this.showError(message);
    }

    render() {
        return <div>
            <div className="grid" style={{padding:0, margin:0}}>
                <Toast ref={(el) => this.toast = el}/>
                <Messages ref={(el) => this.messages = el}/>
                <div style={{display: "flex", width: "100%", marginTop: 10}}>
                    <InputText value={this.state.name} style={{width: "100%"}}
                               name="username"
                               id="username"
                               placeholder="Benutzername"
                               onChange={(e) => this.handleNameChange(e.target.value)}
                               onKeyDown={(e) => this.handelKeyDown(e)}/>
                </div>
                <div style={{display: "flex", width: "100%", marginTop: 10}}>
                    <Password inputStyle={{width:200}} value={this.state.password} style={{marginRight: 5}}
                              name="password"
                              id="password"
                              toggleMask={true}
                              placeholder="Kennwort"
                              onChange={(e) => this.handlePasswordChange(e.target.value)}
                              onKeyDown={(e) => this.handelKeyDown(e)}
                              feedback={false}/>
                    <Button label="Anmelden"
                            disabled={this.state.name.length === 0 || this.state.password.length === 0}
                            type="button" onClick={() => this.doLogin()}/>
                </div>
            </div>
            <div style={{display: "flex", marginTop: 20}}>
                <Button label="Registrieren"
                        icon="pi pi-user-plus"
                        type="button" onClick={() => this.setState({showRegistrieren: true})} style={{marginRight: 5, fontSize:12}}/>
                <Button label="Kennwort vergessen"
                        icon="pi pi-key"
                        type="button" onClick={() => this.setState({showKennwortVergessen: true})} style={{marginRight: 5, fontSize:12}}/>
            </div>
            <RegistrierenDialog visible={this.state.showRegistrieren}
                                onClose={() => this.setState({showRegistrieren: false})}/>
            <KennwortVergessenDialog visible={this.state.showKennwortVergessen}
                                     onClose={() => this.setState({showKennwortVergessen: false})}/>
        </div>
    }
}

Login.propTypes = {
    login: PropTypes.func.isRequired
};

export default Login;
